import React from 'react';
import { Icon } from '@material-tailwind/react';

const MenuItems: React.FC = () => (
  <>
    <span>
      <a href="#">Home</a>
    </span>
    <span>
      <a href="#duiken">Duiken</a>
    </span>
    <span>
      <a href="#tuinwerken">Tuinwerken</a>
    </span>
    <span>
      <a href="#lassen">Laswerken</a>
    </span>
    <span>
      <a href="#klussen">Klussen</a>
    </span>
    <span>
      <a href="#contact">Contact</a>
    </span>
  </>
);

const MobileMenu: React.FC = () => {
  const [menuShow, setMenuShow] = React.useState(false);
  const menuIcon = React.useRef<HTMLElement>(null);
  const closeIcon = React.useRef<HTMLElement>(null);

  function toggleMenu() {
    setMenuShow(!menuShow);
  }

  React.useLayoutEffect(() => {
    if (menuShow) {
      menuIcon.current?.classList.add('hidden');
      closeIcon.current?.classList.remove('hidden');
    } else {
      menuIcon.current?.classList.remove('hidden');
      closeIcon.current?.classList.add('hidden');
    }
  }, [menuShow]);

  return (
    <span className="sm:hidden">
      <nav
        className={`menu absolute top-0 bottom-0 left-0 right-0 h-full p-2 bg-black/60 z-30 flex flex-col justify-evenly items-center duration-200 text-white text-lg ${
          menuShow ? 'translate-y-0' : '-translate-y-full'
        }`}
      >
        <MenuItems />
      </nav>
      <button
        type="button"
        className="hamburger z-50 p-1 cursor-pointer top-4 right-4 text-white absolute bg-black/60"
        onClick={toggleMenu}
      >
        <span ref={menuIcon}>
          <Icon name="menu" />
        </span>
        <span ref={closeIcon} className="hidden">
          <Icon name="close" />
        </span>
      </button>
    </span>
  );
};

const FullMenu: React.FC = () => (
  <nav className="absolute hidden sm:flex left-1/2 -translate-x-1/2 text-white text-base justify-between top-14 w-4/5 z-20 lg:w-2/4 lg:left-auto lg:transform-none lg:right-20 xl:text-xl">
    <MenuItems />
  </nav>
);

const Menu: React.FC = () => (
  <>
    <MobileMenu />
    <FullMenu />
  </>
);

export default Menu;
