import * as React from 'react';
import Footer from './Footer';
import Header from './Header';

const MainLayout: React.FC = ({ children }) => (
  <div className="container mx-auto min-h-screen grid grid-rows-layout">
    <header>
      <Header />
    </header>
    <main>{children}</main>
    <footer>
      <Footer />
    </footer>
  </div>
);

export default MainLayout;
