import React from 'react';
import { Heading4 as H4, CardBody } from '@material-tailwind/react';
import { StaticImage } from 'gatsby-plugin-image';

const Footer: React.FC = () => (
  <div
    id="contact"
    className="w-full bg-white rounded-b-xl overflow-hidden shadow-md p-4 mt-4"
  >
    {/* <CardBody className="grid gap-4 sm:grid-cols-2 content-center items-center justify-items-center"> */}
    <CardBody className="grid gap-8 sm:grid-cols-2 items-center justify-items-center">
      <article className="w-full sm:w-fit">
        <H4>
          <span className="font-project font-normal">Contacteer ons</span>
        </H4>
        <hr className="w-1/5 border-t-4 border-black relative left-1" />
        <div className="mb-4" />
        <address>
          <p className="projectText">Luc Van der Stricht</p>
          <p className="projectText">Tweehagen 80</p>
          <p className="projectText">9170 SINT-GILLIS-WAAS</p>
          <br />
          <p className="projectText">
            <a href="tel:+32470598049">(+32) 0470 59 80 49</a>
          </p>
          <p className="projectText">
            <a href="mailto:info@lucsspecialprojects.be">
              info@lucsspecialprojects.be
            </a>
          </p>
        </address>
        <p className="mt-4">
          <a
            href="https://www.facebook.com/Lucs-Special-Projects-103777014482016"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enableBackground="new 0 0 24 24"
              height="21"
              viewBox="0 0 24 24"
              width="21"
            >
              <rect fill="none" height="24" width="24" />
              <path d="M22,12c0-5.52-4.48-10-10-10S2,6.48,2,12c0,4.84,3.44,8.87,8,9.8V15H8v-3h2V9.5C10,7.57,11.57,6,13.5,6H16v3h-2 c-0.55,0-1,0.45-1,1v2h3v3h-3v6.95C18.05,21.45,22,17.19,22,12z" />
            </svg>
          </a>
        </p>
      </article>
      <div>
        <StaticImage
          src="../images/footerImg.png"
          alt="Duiker foto"
          placeholder="blurred"
          layout="constrained"
          objectFit="scale-down"
          imgStyle={{ borderRadius: '9999px' }}
        />
      </div>
    </CardBody>
  </div>
);

export default Footer;
